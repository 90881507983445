header{
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: white;
}
.header__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header__grid{
    display: flex;
    align-items: center;
    gap: 30px !important;
}
header nav{
    display: flex;
    align-items: center;
    gap: 50px;
}
.header__btn{
    display: flex;
    align-items: center;
    gap: 20px;
}
header h1{
    font-size: 30px;
    color: black;
}
header nav a{
    font-weight: 600;
}
.Mobile__nav{
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}
.accordion-body{
    padding: 10px;
}
.header__btnM{
    display: flex;
    align-items: center;
    gap: 10px;
}
.header__burger{
    border: none;
    background-color: transparent;
    font-size: 25px;
}
.header__burger{
    display: none;
}
.header__accrodion{
    display: none;
}
.header__profile{
    width: 50px;
    height: 50px ;
    border-radius: 50%;
    border: 2px solid #1D4ED6;
    cursor: pointer;
}
.header__profile img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}
.header__grid{
    display: flex;
    align-content: center;
    gap: 5px;
}
.dn{
    display: none;
}
.header__time{
    display: flex;
    align-items: center;
    gap: 5px;
}
.point{
    display: flex;
    align-items: center;
    gap: 1px;
}
.point svg{
    font-size: 20px !important;
}
@media(max-width:780px){
    header nav{
        display: none;
    }
    .header__btn{
        display: none;
    }
    .header__burger{
        display: block;
    }
    .header__accrodion{
        display: block;
    }
}
@media(max-width:400px){
    .header__grid{
        gap: 10px !important;
    }
}