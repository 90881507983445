footer{
    background-color: #1D4ED6;
    padding: 10px;
    padding-bottom: 40px;
 }
 .Footer__email{
    display: flex;
    gap: 5px;
    align-items: center;
    color: white;
    margin-bottom: 10px;
 }
 .Footer__email svg{
    font-size: 20px;
 }
 .itlive{
    font-weight: bold;
    color: red;
 }
 .Footer__it{
    display: block;
    margin-top: 10px;
    font-weight: bold;
    color: white;
 }
 footer h5{
    color: white;
 }
 .footer__wrapper{
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
 }
 .Footer__media{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
 }
 .Footer__media svg{
    font-size: 30px;
    color: white;
 }
 .Footer__media{}
 @media(max-width:850px){
   .footer__wrapper{
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 30px   ;
   }
 }