.Test{
    margin-top: 60px;
    margin-bottom: 60px;
    padding: 50px;
}
.Test__Card{
    border-radius: 5px;
    padding: 20px;
    background-color: white;
    border: 1px solid rgb(185, 185, 185);
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.Test__Card h2{
    font-size: 20px;
}
.Test__card__grid{
    display: flex;
    justify-content: space-between;

}
.Test__Card__otv{
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
}
.Test__Card__otv label{
    display: flex;
    align-items: center;
    gap: 5px;
}
.Test__Card__otv label input{
    width: 18px;
    height: 18px;
}
.Test__Card__grid__time{
    display: flex;
    align-items: center;
    gap: 5px;
}
.Test__card__grid svg{
    color: #1D4ED6;
    font-size: 20px;
}
.Test__card__grid span{
    font-weight: bold;
}
.Test__Card{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
}
.Test__card__grid__btn{
    display: flex;
    align-items: center;
    gap: 20px;
}
.card__wrraperr{
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: 100%;
}
.Test__wrapper{
    display: flex;
    gap: 10px;
}
.custom-card{
    height: fit-content;
}
.Test__card__grid img{
    width: 250px;
    height: 180px;
    object-fit: cover;
}
.Test__card__grid2{
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: start;
}
.Test__card__Bkv{
    display: flex;
    align-items: center;
    gap: 10px;
}
.test__card__text2{
    font-weight: 500 !important;
}
.single-letter-input {
    background-color: rgba(70, 69, 69, 0.512);
    padding: 0px;
    color: white;
    width: 40px; /* Устанавливаем ширину поля ввода */
    text-align: center; /* Выравниваем текст по центру */
    font-size: 15px; /* Устанавливаем размер шрифта */
    height: 40px; /* Устанавливаем высоту поля ввода */
}
.NoresTest{
    height: 100vh;
}
.Test__card__grid{
    margin-top: 20px;
}
@media(max-width:850px){
    .Test__wrapper{
        flex-direction: column;
        gap: 10px;
    }
}
@media(max-width:590px){
    .Test__card__grid{
        flex-direction: column;
        align-items: start;
        gap: 20px;
    }
}
@media(max-width:500px){
    .Test{
        padding: 5px;
    }
}

@media(max-width:360px){
    .Test__Card img{
        width: 100%;
    }
    .Test__card__Bkv input{
        width: 30px;
        height: 30px;
    }
}