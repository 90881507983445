.HomeTest {
    margin-top: 40px;
    position: relative;
}

.HomeTest h2 {
    margin-bottom: 25px;
    font-weight: 700;
}

.HomeTest__swiper {
    padding: 10px 50px;
    width: 100%;
    overflow: hidden;
}

.HomeTest__Card {
    border-radius: 10px;
    padding: 10px;
    border: 3px solid #1D4ED6;
    display: flex;
    align-items: center;
    gap: 15px;
    width: 280px; /* Adjust width to fit your design */
    background-color: white;
    margin: 60px 0px;
    margin-top: 20px;
    transition: 0.5s;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

}
.HomeTest__Card:hover{
	box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}
.HomeTest__Card h3{
    font-size: 20px;
}
.HomeTest__Card p{
    font-size: 15px;
}

.HomeTest__Card img {
    width: 100px;
    object-fit: cover;
    height: 100px;
    border-radius: 5px;
}

.mySwiper {
    width: 100%;
    padding: 0 10px;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper{
    position: static !important;
}
.swiper-button-prev {
    left: 1% !important; 
    top: 65% !important;/* Adjust position from the left */
}

.swiper-button-next {
    right: 1% !important; 
    top: 65% !important;/* Adjust position from the left */
}
.dec{
    display: none;
}
.mb{
    display: none;
}
.lc{
    display: none;
}
@media(max-width:1260px){
    .dec{
        display: block;
    }
    .pc{
        display: none;
    }
}
@media(max-width:1000px){
    .dec{
        display: none;
    }
    .mb{
        display: block;
    }
}
@media(max-width:730px){
    .lc{
        display: block;
    }
    .mb{
        display: none;
    }
    .HomeTest__Card{
        width: 100%;
    }
}