.ContactForm{
    padding: 10px;
    margin-bottom: 50px;
}
.ContactForm__wrapper{
    border: 1px solid rgb(185, 185, 185);
    padding: 20px ;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
}