*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body{
  background-color:#F7F7F8 !important ;
}
a{
  text-decoration: none !important;
}
.Container{
  width: 1430px;
  max-width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}
/* Убираем стрелки в Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Убираем стрелки в Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


::-webkit-scrollbar{
  width: 8px;
}

::-webkit-scrollbar-track{
  background: white;
}

::-webkit-scrollbar-thumb{
  background:#1D4ED6;
  border-radius: 15px;

}
/* App.css */
.scroll-wrapper{
  height: 100vh !important;
}
.Loading{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
.DemoCard2 h3{
  font-size: 20px !important;
}
.HomeTest__Card{
  cursor: pointer;
}
.Loading__mb{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.Nores{
  display: flex;
  align-items: center;
  justify-content: center;
}
.HomeTest__Card {
  /* Existing styles */
  cursor: pointer; /* Default cursor */
}

.HomeTest__Card.finished {
  opacity: 0.5; 
  cursor: not-allowed;
  pointer-events: none; 
}
.heaer__ball h5{
  font-size: 15px !important;
}
.Result{
  height: 100vh;
}
