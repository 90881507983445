.HomeHero{
    background-color: #1D4ED6;
    padding: 15px 0px;
}
.HomeHero h1{
    text-align: center;
    margin: 0 auto;
    font-size: 40px;
    font-weight: 900;
    width: 750px;
    color: white;
    letter-spacing: 0.2px;
    line-height: 56px;
}
.HomeHero p{
    margin-top: 15px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: white;
}
@media(max-width:830px){
    .HomeHero h1{
        width: 100%;
    }
}
@media(max-width:550px){
    .HomeHero h1{
        font-size: 30px;
    }
}
@media(max-width:450px){
    .HomeHero h1{
        font-size: 20px;
        line-height: 36px;
    }
    .HomeHero p{
        font-size: 12px;
    }
}