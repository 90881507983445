.Profile{
    padding: 20px;
}
.Profile__title{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Profile__wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 100px 0px;
    /* height: 100vh; */
}
.Profile__card{
    text-align: center;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border-radius: 10px;
    border: 1px solid rgb(185, 185, 185);
    padding: 10px 20px ;
}
.Profile__wrapper img{
    width: 150px;
    height: 150px;
    border: 2px solid black;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}
.Profile__wrapper__grid{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.Profile__wrapper span{
    font-size: 20px;
}
.Profile__wrapper h3{
    font-size: 22px;
}
.profile__btn{
    margin-top: 20px;
    width: 100%;
}
.Profile__title__grid{
    display: flex;
    align-items: center;
    gap: 10px;
}
@media(max-width:410px){
    .Profile__title{
        flex-direction: column;
        gap: 10px;
    }
    .Profile__title__grid{
        flex-direction: column;
        gap: 10px;
    }
}