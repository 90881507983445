.Register form div{
    display: block;
    margin-bottom: 20px;
}
.PasswordEye{
    position: relative;
}
.PasswordEye div{
    position: absolute;
    top: 35px;
    right: 10px;
    background-color: transparent;
    border: none;
}
.PasswordEye div svg{
    cursor: pointer;
    font-size: 25px;
}