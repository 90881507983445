.AboutUsInfo{
margin-top: 30px;
}
.AboutUsInfo h1{
    margin-bottom: 20px;
}
.AboutUsInfo p{
    font-size: 17px;
    color: #1A1C2C;
    font-weight: 400;
    line-height: 30px;
}
.AboutUsInfo span{
    font-weight: bold;
}
.AboutusInfo__text p{
    margin: 0 !important;

}
.AboutusInfo__text{
    margin-top: 20px;
    margin-bottom: 20px;
}
@media(max-width:450px){
    .AboutUsInfo h1{
        font-size: 20px;
    }
    .AboutUsInfo p{
        font-size: 15px;
    }
}