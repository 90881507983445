.ChapterCard{
    margin: 30px 0px ;
    margin-bottom: 60px;
}
.ChapterCard__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;
}
.custom-card {
    transition: box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .custom-card:hover {
    box-shadow: inset 0 0 10px #1D4ED6; /* Inner shadow */
  }

  @media(max-width:1250px){
    .ChapterCard__wrapper{
        width: 100%;
    }
  }
  
  @media(max-width:1050px){
    .ChapterCard__wrapper{
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
    }
  }
@media(max-width:420px){
    .custom-card{
        width: 100% !important;
    }
}
@media(max-width:380px){
  .card-container{
      width: 100% !important;
  }
}
