.HomeFAQ {
    margin-top: 40px;
    padding-bottom: 100px;
}

.HomeFAQ h2 {
    margin-bottom: 25px;
    font-weight: 700;
}
.HomeFAQ__wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 30px;
}
.HomeFAQ__accordion{
    width: 100%;
}
.HomeFAQ__accordion .accordion-item {
    padding: 0px; /* Adjust padding as needed */
  }
  
  .HomeFAQ__accordion .accordion-header {
    padding: 0px; /* Adjust padding as needed */
    margin: 0 !important;
  }
  
  .HomeFAQ__accordion .accordion-body {
    padding: 11px; /* Adjust padding as needed */
  }
  .HomeFAQ__accordion .accordion-header p{
    padding: 0px; /* Adjust padding as needed */
    margin: 0 !important;
  }
  @media(max-width:600px){
    .HomeFAQ__wrapper{
        flex-direction: column;
        
    }
  }