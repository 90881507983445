.ContactCards{
    margin-top: 20px;
    margin-bottom: 20px;
}
.ContactCard__card{
    padding: 10px;
    border: 1px solid rgb(185, 185, 185);
    border-radius: 10px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    background-color: white;
}
.ContactCard__card__svg{
    padding: 10px;
    background-color: #1D4ED6;
    color: white;
    font-size: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
}
.ContactCard__card span{
    margin-top: 20px;
    display: block;
    font-size: 25px;    
}
.ContactCard__card a{
    font-size: 20px;
}
.ContactCards__wrapper{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 30px;
}
@media(max-width:870px){
    .ContactCards__wrapper{
        grid-template-columns: repeat(2,1fr);
    }
}
@media(max-width:670px){
    .ContactCards__wrapper{
        grid-template-columns: repeat(1,1fr);
    }
}
@media(max-width:670px){
    .ContactCard__card a{
        font-size: 15px;
    }
    .ContactCard__card span{
        font-size: 20px;
    }
}
